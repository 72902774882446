import React from 'react'
import { useSelector } from 'react-redux';
import { selectUser } from '../features/userSlice';
import logo from "../assets/logo.png";
import LinkButton from '../components/LinkButton';
import telegram from "../assets/telegram.png";
import twitter from "../assets/twitter.png";
import youtube from "../assets/youtube.png";

const Earn = () => {
  const user = useSelector(selectUser);

  // Social links (replace with actual URLs)
  const socialLinks = {
    telegram: "https://t.me/your_channel",
    twitter: "https://twitter.com/your_handle",
    youtube: "https://youtube.com/your_channel",
    cmc: "https://www.coinmarketcap.com/currencies/your-coin"
  };

  return (
    <div className='text-white mb-24'>
      <div className='flex items-center justify-center py-8'>
        <div className='rounded-full p-4'>
          <img className='w-28 h-28 object-contain' src={logo} alt='M' />
        </div>
      </div>
      <p className='text-center font-bold text-3xl'>Earn coins</p>
      <div className='mx-4 mt-8'>
        <p className='text-lg font-bold mb-4'>Important tasks</p>
        <LinkButton
          image={"referral"}
          name={
            Object.keys(user.referrals).length >= 10
            ? `You invited ${Object.keys(user.referrals).length} friends`
            : `Invited ${10 - Object.keys(user.referrals).length} friends`
          }
          amount={100000}
          link={"referral"}
        />
      </div>

        {/* Social Links Section */}
      <div className='mt-8 text-center'>
        <p className='text-lg font-bold mb-4'>Follow us on Social Media</p>
        <div className='flex justify-center space-x-6'>
          <a href={socialLinks.telegram} target="_blank" rel="noopener noreferrer" className='text-2xl'>
            <img src={telegram} alt="Telegram" className="w-8 h-8 object-contain" /> {/* Telegram Logo */}
          </a>
          <a href={socialLinks.twitter} target="_blank" rel="noopener noreferrer" className='text-2xl'>
            <img src={twitter} alt="Twitter" className="w-8 h-8 object-contain" /> {/* Twitter Logo */}
          </a>
          <a href={socialLinks.youtube} target="_blank" rel="noopener noreferrer" className='text-2xl'>
            <img src={youtube} alt="YouTube" className="w-8 h-8 object-contain" /> {/* YouTube Logo */}
          </a>
        </div>
      </div>
    </div>
  )
}

export default Earn;